@import './bootstrap.scss';

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input-webkit-autofill:active
{
  /* stylelint-disable */
  -webkit-box-shadow: 0 0 0 30px $white inset !important;
  /* stylelint-enable */
}

body {
  font-family: Oxygen, sans-serif;
  font-weight: 400;
  font-style: normal;
  min-width: 375px;
}

@media (width <= 600px) {
  html {
      font-size: 0.8rem;
  }
}

.selectable-row:hover {
  background-color: $light;
  cursor: pointer;
}


