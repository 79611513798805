@use "sass:map";
@import "~bootstrap/scss/functions.scss";
@import "~bootstrap/scss/variables.scss";

$primary: #846cfc;
$secondary: #60eddf;
$custom-theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
);
$theme-colors: map.merge($theme-colors, $custom-theme-colors);
$pagination-color: $primary;
$pagination-hover-color: $light;
$pagination-focus-color: $light;
$pagination-disabled-color: $light;
$pagination-active-bg: $primary;
$pagination-hover-bg: $primary;
$pagination-disabled-bg: $primary;
$pagination-focus-box-shadow: 0 0 0 0.1rem $primary;
$pagination-disabled-border-color: $border-color;
$pagination-hover-border-color: $border-color;
$pagination-active-border-color: $border-color;
$pagination-border-color: $border-color;


@import "~bootstrap/scss/bootstrap.scss";

.btn-primary, .btn-primary:hover {
    color: white
}

.form-control {
    background-color: $light;
}
  
.form-control:focus {
    box-shadow: 0 0 0 0.1rem $primary;
    background-color: $white;
}

.form-select {
    background-color: $light;
}
  
.form-select:focus {
    box-shadow: 0 0 0 0.1rem $primary;
    background-color: $white;
}

.nav-link {
    color: $primary
}